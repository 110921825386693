div  .quotes-list   th, div .quotes-list   td{
  
  padding:0;

 text-align : center;
  font-size:1em;
vertical-align: middle;
 text-align:center;
 
}

div  .quotes-list .extra-large{
  width:20em;
  padding:0;
}
 
 
.background{
  background-color :#043d58;
}

.filter-menu, .page-menu{
  background-color:  white;
}
 
.filter-menu{
  border-bottom:2px solid lightgrey;
  padding-left:1em;
  padding-top:0.5em;
  font-size: 0.8em;
}

/* .form-control, .smaller-input{
  max-width: 60%;
} */

.col-1{
  max-width: 7% !important;
}

.fixed-menu{
  position: fixed;
  top: 0;
  width: 100%;
  z-index:100;

  border-bottom:2px solid lightgrey;
  padding-left:1em;
  padding-top:0.5em;
}

 

div.quotes-list {
  height: 75vh;
}
div  .quotes-list table {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}
div  .quotes-list table thead {
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
  border-bottom:  3px solid #0076ad;
  color: white;
}
div  .quotes-list table tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
}
div  .quotes-list table tbody tr {
  width: 100%;
}
div  .quotes-list table thead,  div  .quotes-list table tbody tr {
  display: table;
  table-layout: fixed;
}

.testp {
  color: black;
  background-color: white;
  /* overflow: hidden;  */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  padding: 20px;

  text-align: left;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  position: relative;
}

.close_popup {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 25px;
  height: 25px;
  background-color: red;
  border: none;
  cursor: pointer;
  border-radius: 50%; /* Crée un cercle */
  display: flex;
  align-items: center;
  justify-content: center;
}

.close_popup::before,
.close_popup::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: white;
}

.close_popup::before {
  transform: rotate(45deg);
}

.close_popup::after {
  transform: rotate(-45deg);
}

/* .table-container {
  max-height: 160px; 
  overflow-y: auto; 
} */

